/**
 * 견적서(하우머치) 타입 정의 공통타입
 */

let type = {};

type.payType = {
    'CASH': '현금',
    'CARD': '카드',
}

type.status =  Object.freeze({
    PROGRESS   : '견적진행중',
    SUCCESS    : '견적성사',
    CANCEL     : '견적취소',
})

type.statusClass = Object.freeze({
    PROGRESS: 'fc-howcost',
    SUCCESS: 'fc-green',
    CANCEL: 'fc-g'
})

module.exports = type;