<template>
  <div id="wrapper" class="bg-w noto-sans fc-b">

    <div class="pt-3 mt-3 container">

      <div class="pt-3 df-center df-between">
        <div class="top-title df-center">
          <h4 class="mr-1">하우머치</h4>
          <span>(온라인 견적요청)</span>
        </div>

        <div class="top-title df-center">
          <span class="fc-g">자재를 온라인을 통해 손쉽게 견적 받아보세요</span>
        </div>
      </div>

      <hr style="margin-top:0.125rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

      <div class="df-center mt-2 px-3 py-3 fs-14">
        <p class="mr-1 fb">[{{ payTypeConv(detailInfo.payType) }}]</p>
        <p>{{ detailInfo.title }}</p>
      </div>

      <div class="df-center mt-1 px-3 py-2 df-between" style="background-color: #efefef;">
        <div class="df-center">
          <p class="mr-2">신청자:</p>
          <p v-if="detailInfo.regId === user.id || user.role === 'A'">{{ detailInfo.regNick }}</p>
          <p v-else>{{ nickMasking(detailInfo.regNick) }}</p>
        </div>
        <div class="df-center">
          <div class="df-center px-4" style="border-right: solid 1px #c1c1c1;">
            <p class="mr-1">결제방법:</p>
            <p class="fb">{{ payTypeConv(detailInfo.payType) }}</p>
          </div>
          <div class="df-center px-4" style="border-right: solid 1px #c1c1c1;">
            <p class="mr-1">참고파일:</p>
            <p v-if="detailInfo.file && userRoleVerify()" class="fb">
              <a class="c-pointer" style="color: #005ce6;" @click="download">다운로드</a>
            </p>
            <p v-else-if="!detailInfo.file" class="fb fc-g">없음</p>
            <p v-else class="fb fc-g">비공개</p>
          </div>
          <div class="df-center px-4">
            <p class="mr-1">상태:</p>
            <p class="fb" :class="statusClass(detailInfo.status)">{{ statusTypeConv(detailInfo.status) }}</p>
          </div>
        </div>
      </div>

      <div class="px-4" style="margin: 1rem 0 1.252rem 0;">
        <div class="df-between mt-4 mb-4 fc-gg">
          <div class="">
            <h5>{{detailInfo.projectName}}</h5>
            <p><strong class="fb">공사구분: </strong>{{detailInfo.part}}</p>
            <p><strong class="fb">공사기간: </strong>{{detailInfo.startDate}} ~ {{detailInfo.endDate}}</p>
            <div class="df-center">
              <p class="mr-3"><strong class="fb">건축면적: </strong>{{detailInfo.buildArea}}m<sup>2</sup>({{detailInfo.buildArea2}}평)</p>
              <p><strong class="fb">연면적: </strong>{{detailInfo.grossArea}}m<sup>2</sup>({{detailInfo.grossArea2}}평)</p>
            </div>
          </div>

          <div class="" v-if="userRoleVerify() && detailInfo.contactInfo">
            <h5>담당자 정보</h5>
            <p><strong class="fb">회사명: </strong>{{detailInfo.contactInfo.compName}}</p>
            <p><strong class="fb">이　름: </strong>{{detailInfo.contactInfo.name}}</p>
            <p><strong class="fb">연락처: </strong>{{detailInfo.contactInfo.tel}}</p>
            <p><strong class="fb">이메일: </strong>{{detailInfo.contactInfo.email}}</p>
          </div>
        </div>

        <b-table
            head-variant="white"
            :items="detailInfo.resources"
            :fields="fields"
            sort-icon-left
            responsive="sm"
            class="items-table mb-0"
        ></b-table>

        <div class="df-end px-3 py-1" style="background-color: #efefef;">
          <div class="df mr-3">
            <p class="mr-1">카테고리:</p>
            <p class="fb">{{ howmuchCategoryOpts(detailInfo.brandType) }}</p>
          </div>
          <div class="df">
            <p class="mr-1">요청브랜드:</p>
            <p class="fb">{{ detailInfo.brandName }}</p>
          </div>
        </div>
      </div>

      <div class="df-align-center pb-4 fs-11 mb-3 mt-4">
        <p>{{ detailInfo.content }}</p>
        <p class="fc-g" v-if="!detailInfo.content">( 요청사항 없음 )</p>
      </div>

      <hr style="margin-top:0.125rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

      <div class="df-center df-between">
        <div class="df-start">
          <!-- Admin 권한 -->
          <div v-if=" user.role === 'A' " class="df-center">
            <b-dropdown class="mr-2" variant="outline-danger" toggle-class="br-0" text="상태변경">
              <b-dropdown-item v-for="[key, value] in statusEntries()" :key="key" :disabled="key === detailInfo.status" @click="statusChange(key)">{{ value }}</b-dropdown-item>
            </b-dropdown>

            <b-button class="btn-outline-howcost-red mr-2" @click="deletePost()">견적삭제</b-button>
          </div>

        </div>
        <div class="df-end">
          <b-button v-if=" userRoleVerify() && detailInfo.status !== 'CANCEL' " class="btn-outline-howcost-red mr-2" @click="cancelPost()">견적취소</b-button>
          <b-button class="btn-outline-howcost" href="#/service/howmuch">목록가기</b-button>
        </div>
      </div>

      <!--   판매자 견적이 댓글처럼 추 후 들어갈 예정   -->
<!--      <div>
      </div>-->

    </div>

    <Spinner :showSpinner="loading" />

  </div>
</template>


<script>
import {
  apiCall,
  apiMultiPart,
  cloneVar,
  alertError,
  alertWarn,
  alertSuccess,
  alertConfirm,
  alertPageMove,
  warningAlertPageMove,
  getMask,
  warpSite, nickMask, warpSiteDirectMove,
} from "@/common/utils";
import qType, {statusClass} from '@/common/quoteType';
import { HowmuchCategoryOpts } from '@/common/specType';

export default {
  name: 'HowMuchViewDetail',
  components: {},
  props: ['boardSeq'],
  data() {
    return {
      loading: true,
      fields: [
        { key: "CODE", label: "코드", thClass: "w15" },
        { key: "NAME", label: "품명", thClass: "w35" },
        { key: "STAD", label: "규격", thClass: "w30" },
        { key: "UNIT", label: "단위", thClass: "w10" },
        { key: "QUAN", label: "수량", thClass: "w10" },
        // { key: "REMARK", label: "비고", thClass: "w20" },
      ],
      detailInfo: {},
    };
  },

  computed: {
    user() { return this.$store.state.userStore.user; },
    isAuth() { return this.$store.state.userStore.isAuth; }
  },

  async created() {
    try {
      const r = await apiCall('GET', `/api/how-much/view-detail/${this.boardSeq}`);

      if (r.code === 404) {
        alert("존재하지 않는 게시물입니다.");
        warpSiteDirectMove('service/howmuch');
        // history.back();
      }

      if (r.code === 200) {
        this.detailInfo = r.result;
        this.loading = false;
        // console.log("detailInfo ======> ", this.detailInfo);
      }

      // A이면 무시 (추후.. P 추가 할수도)
      if (this.user.role !== 'A') {
        await this.userViewRoleVerify();
      }
    } catch (e) {
      console.error(e);
    }
  },

  methods: {
    payTypeConv(type) { return qType.payType[type] },
    statusTypeConv(status) { return qType.status[status] },
    statusClass(status) { return statusClass[status] },
    nickMasking(nick) { return nickMask(nick) },
    statusEntries() { return Object.entries(qType.status) },
    howmuchCategoryOpts(type) {
      const result = HowmuchCategoryOpts.find(item => item.value === type) || HowmuchCategoryOpts[0];
      return result.text;
    },

    async userViewRoleVerify() {
      if ( this.detailInfo.isSecret && this.user.id !== this.detailInfo.regId ) {
        alert("비공개 견적은 열람할 수 없습니다.");
        return history.back();
      }
      if ( this.detailInfo.status === 'CANCEL' && this.user.id !== this.detailInfo.regId ) {
        alert("취소된 견적은 열람할 수 없습니다.");
        return history.back();
      }
      if ( !this.isAuth ) {
        alert("로그인이 되어있지 않아 열람할 수 없습니다.");
        return history.back();
      }
    },
    userRoleVerify() {
      return ( this.isAuth && (this.user.role === 'A' || this.user.id === this.detailInfo.regId) );
    },

    async deletePost() {
      try {
        if (confirm("견적이 완전 삭제되며 복구할 수 없습니다.\n정말 삭제하시겠습니까?")) {
          const r = await apiCall('DELETE', `/api/how-much/delete/${this.boardSeq}`);
          if (r.code === 200) {
            alert("견적이 삭제되었습니다.");
            warpSiteDirectMove('service/howmuch');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    async cancelPost() {
      try {
        if (confirm("견적이 취소되면 더 이상 견적제안을 받아볼 수 없습니다.\n정말 취소하시겠습니까?")) {
          const r = await apiCall('PUT', `/api/how-much/cancel/${this.boardSeq}`);
          if (r.code === 200) {
            alert("견적이 취소되었습니다.");
            window.location.reload();
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    async download() {
      try {
        const params = {
          file: this.detailInfo.file,
          fileName: this.detailInfo.fileName,
        }
        const response = await apiCall('DOWNLOAD', `/api/how-much/file/download`, params);

        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = params.fileName;
        link.click();

        URL.revokeObjectURL(url);
      } catch (e) {
        console.error(e);
      }
    },
    async statusChange(key) {
      try {
        const r = await apiCall('PUT', `/api/how-much/status/${this.detailInfo.seq}`, { status: key });
        if (r.code === 200) {
          this.detailInfo = r.result;
          window.location.reload();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style>

.items-table {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

</style>